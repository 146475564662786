import Layout1 from '@/layout/Layout1'

export default [{
  path: '/admin',
  component: Layout1,
  children: [{
    path: 'users',
    component: () => import('@components/admin/user/List')
  }, {
    path: 'users/form',
    component: () => import('@components/admin/user/Form')
  }, {
    path: 'users/form/:id',
    component: () => import('@components/admin/user/Form')
  },
  {
    path: 'news',
    component: () => import('@components/admin/news/List')
  }, {
    path: 'news/form',
    component: () => import('@components/admin/news/Form')
  }, {
    path: 'news/form/:id',
    component: () => import('@components/admin/news/Form')
  },
  {
    path: 'events',
    component: () => import('@components/admin/events/List')
  }, {
    path: 'events/form',
    component: () => import('@components/admin/events/Form')
  }, {
    path: 'events/form/:id',
    component: () => import('@components/admin/events/Form')
  },
  {
    path: 'extensions',
    component: () => import('@components/admin/interactive-extensions/List')
  },
  {
    path: 'extensions/form',
    component: () => import('@components/admin/interactive-extensions/Form')
  },
  {
    path: 'extensions/form/:id',
    component: () => import('@components/admin/interactive-extensions/Form')
  },
  {
    path: 'ar-elements',
    component: () => import('@components/admin/ar-elements/List')
  },
  {
    path: 'ar-elements/form',
    component: () => import('@components/admin/ar-elements/Form')
  },
  {
    path: 'ar-elements/form/:id',
    component: () => import('@components/admin/ar-elements/Form')
  },
  {
    path: 'libraries',
    component: () => import('@components/admin/libraries/List')
  },
  {
    path: 'libraries/form',
    component: () => import('@components/admin/libraries/Form')
  },
  {
    path: 'libraries/form/:id',
    component: () => import('@components/admin/libraries/Form')
  },
  {
    path: 'feedback',
    component: () => import('@components/admin/feedback/List')
  },
  {
    path: 'feedback/form',
    component: () => import('@components/admin/feedback/Form')
  },
  {
    path: 'feedback/form/:id',
    component: () => import('@components/admin/feedback/Form')
  },
  {
    path: 'analytics',
    component: () => import('@components/admin/feedback/Analytics')
  },
  {
    path: 'analytics/annotations',
    component: () => import('@components/admin/feedback/AnalyticsAnotations')
  },
  {
    path: 'analytics/3dapps',
    component: () => import('@components/admin/feedback/Analytics3D')
  }]
}]
